/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateConnectionCommand } from '../models/CreateConnectionCommand';
import type { GetConnectionQuery } from '../models/GetConnectionQuery';
import type { GetConnectionQueryResult } from '../models/GetConnectionQueryResult';
import type { GetConnectionsQuery } from '../models/GetConnectionsQuery';
import type { GetConnectionsQueryResult } from '../models/GetConnectionsQueryResult';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ConnectionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiReportingConnectionsCreateConnection(
        requestBody?: CreateConnectionCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/Connections/CreateConnection',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetConnectionQueryResult OK
     * @throws ApiError
     */
    public postApiReportingConnectionsGetConnection(
        requestBody?: GetConnectionQuery,
    ): CancelablePromise<GetConnectionQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/Connections/GetConnection',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiReportingConnectionsUpdateConnection(
        requestBody?: CreateConnectionCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/Connections/UpdateConnection',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetConnectionsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingConnectionsGetConnections(
        requestBody?: GetConnectionsQuery,
    ): CancelablePromise<GetConnectionsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/Connections/GetConnections',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
