<template>
  <Box flex :direction="direction" :class="formControlClasses" v-bind="$attrs">
    <InputLabel :id="id" :hide="hideLabel || !label" :disabled="disabled" :required="required">
      <Box component="span" flex justify="center" alignItems="center" spaceX="0.5">
        <Typography variant="inherit">
          {{ label }}
        </Typography>
        <Tooltip
          v-if="$slots.tooltip || tooltip"
          placement="top"
          offsetDistance="8"
          v-bind="tooltipProps"
        >
          <template #default>
            <Icon
              icon="info"
              size="xs"
              variant="outlined"
              color="inherit"
            />
          </template>
          <template #content>
            <slot name="tooltip">
              {{ tooltip }}
            </slot>
          </template>
        </Tooltip>
      </Box>
    </InputLabel>

    <Box flex>
      <slot v-bind="{ id, disabled, error: hasError }" />
    </Box>

    <Transitions>
      <InputHelperText v-if="inputHelperText" :variant="inputHelperVariant" pt="1">
        {{ inputHelperText }}
      </InputHelperText>
    </Transitions>
  </Box>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { computed, provide, toRef, useSlots } from 'vue'
import { useField } from 'vee-validate'

import { useFormEvents } from '@lasso/shared/hooks'

import Box from '../Box/Box.vue'
import Tooltip from '../Tooltip/Tooltip.vue'
import Typography from '../Typography/Typography.vue'
import Icon from '../Icon/Icon.vue'

import InputLabel from '../Input/InputLabel/InputLabel.vue'
import InputHelperText from '../Input/InputHelperText.vue'
import Transitions from '../Transitions/Transitions.vue'

import { FormControlProps } from './types'

const props = withDefaults(defineProps<FormControlProps>(), {
  id: '',
  fullWidth: true,
  direction: 'col',
  label: '',
  hideLabel: false,
  helperText: '',
  required: false,
  disabled: false,
  errorText: '',
  keepValueOnUnmount: true,
})

const slots = useSlots()

const formControlClasses = computed(() => {
  const gapProp = props.direction === 'col' ? 'gap-y-1.5' : 'gap-x-1.5'

  return {
    'w-full': props.fullWidth,
    [gapProp]: !!slots.default,
  }
})

const id = toRef(props, 'id') as Ref<string>
const vee = props.id
  ? useField(id, undefined, {
    // @ts-expect-error TODO: rewrite form components
      type: 'input',
      validateOnValueUpdate: false,
      keepValueOnUnmount: props.keepValueOnUnmount,
    })
  : null

const errorMessage = computed(() => vee?.errorMessage.value || props.errorText || '')
const hasError = computed(() => !!errorMessage.value)
const inputHelperText = computed(() => errorMessage.value || props.helperText || '')
const inputHelperVariant = computed(() => (hasError.value ? 'error' : 'default'))

const formEvents = useFormEvents()

formEvents.validationError.on(() => {
  if (hasError.value) {
    formEvents.fieldError.trigger()
  }
})

provide(
  'formControlHasError',
  computed(() => hasError.value),
)

defineExpose({
  hasError,
})
</script>
