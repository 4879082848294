import { createInjectionState } from '@lasso/shared/utils'

import { AxiosInstance } from 'axios'

import { bindApiMethods } from '@lasso/api-shared'

import { useApiCache } from '@lasso/shared/hooks'

import { CancelablePromise, OpenAPIConfig, ReportingApi } from './generated'
import { AxiosHttpRequest } from './generated/core/AxiosHttpRequest'
import { ApiRequestOptions } from './generated/core/ApiRequestOptions'
import { request } from './generated/core/request'

export const [useProvideReportingApi, useReportingApi] = createInjectionState(
  (axiosInstance: AxiosInstance, options: Partial<OpenAPIConfig> = {}) => {
    const [{
      reporting,
      connections,
    }, methodsMapping] = bindApiMethods(
      new ReportingApi(
        options,
        class AxiosRequest extends AxiosHttpRequest {
          public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
            return request(this.config, options, axiosInstance)
          }
        },
      ),
    )

    useApiCache().aliasCacheKeys(methodsMapping)

    return {
      getReportingCampaigns: reporting.postApiReportingGetCampaigns,
      getReportingAdGroups: reporting.postApiReportingGetAdGroups,
      getBuilderFieldsConfig: reporting.postApiReportingGetBuilderFieldsConfig,

      createConnection: connections.postApiReportingConnectionsCreateConnection,
      getConnection: connections.postApiReportingConnectionsGetConnection,
    }
  },
  { throwWhenNotProvided: 'Reporting API was not provided' },
)
